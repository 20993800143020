import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Mauenheim: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Mauenheim,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Mauenheim"
          points="509,472 519,496 495,502 471.3,473.4 473,472 483,474 499,484 505,480 505,472"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 482.8924 481.7877)"
        >
          <tspan x="0" y="0">
            Mauen-
          </tspan>
          <tspan x="0" y="14.4">
            heim
          </tspan>
        </text>
      </g>
    </>
  );
});
